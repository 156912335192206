<template>
<div>
    <b-row>
        <b-col md="12">
            <b-row>
                <b-col md="12">
                    <CCard>
                        <CCardHeader color="dark" text-color="white">
                            <i class="fas fa-briefcase fa-md mr-1"></i> <span class="h5"> {{datosCliente.razonSocial}}</span>
                        </CCardHeader>
                        <CCardBody>
                            <b-tabs variant="pills">
                                <b-tab active>
                                    <template slot="title">
                                        <i class="fas fa-receipt fa-md"></i> Información
                                    </template>

                                    <div class="mt-3">
                                        <b-row>
                                            <b-col md="12">
                                                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                                    <b-form @submit.stop.prevent="handleSubmit(modificarCliente)">
                                                        <b-row>
                                                            <b-col md="4">
                                                                <validation-provider name="número de ruc" :rules="{ required: true,numeric:true,min:11,max:11 }" v-slot="validationContext">
                                                                    <b-form-group label="Número de ruc:" class="mb-2">
                                                                        <b-overlay :show="cargaApi" opacity="0.5" spinner-small>
                                                                            <b-input-group>
                                                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese número de RUC" v-model="datosCliente.numeroRuc"></b-form-input>
                                                                                <b-input-group-append>
                                                                                    <b-button :disabled="disabledBtnApi" v-c-tooltip.hover.click="'Buscar'" variant="dark" @click="apiSunat()">
                                                                                        <i class="fas fa-search my-0 mx-0"></i>
                                                                                    </b-button>
                                                                                </b-input-group-append>
                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                            </b-input-group>
                                                                        </b-overlay>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>

                                                            <b-col md="4">
                                                                <validation-provider name="razón social" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Razón social:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la razón social" v-model="datosCliente.razonSocial"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>

                                                            <b-col md="4">
                                                                <validation-provider name="nombre comercial" v-slot="validationContext">
                                                                    <b-form-group label="Nombre comercial:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre comercial" v-model="datosCliente.nombreComercial"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <!-- <b-col md="4">
                                                                        <validation-provider name="departamento" :rules="{ required: true }" v-slot="validationContext">
                                                                            <b-form-group label="Departamento:">
                                                                                <b-form-select :state="getValidationState(validationContext)" @cha()" id="departamento" value-field=idDepartamento text-field="nombre" :options="listaDepartamentos" v-model="datosCliente.idDepartamento">
                                                                                    <template v-slot:first>
                                                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                    </template>
                                                                                </b-form-select>
                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </b-col>
                                                                    <b-col md="4">
                                                                        <validation-provider name="provincia" :rules="{ required: true }" v-slot="validationContext">
                                                                            <b-form-group label="Provincia:">
                                                                                <b-form-select :state="getValidationState(validationContext)" id="provincia" @change="obtenerDistritoChange()" value-field=idProvincia text-field="nombre" :options="listaProvincias" v-model="datosCliente.idProvincia">
                                                                                    <template v-slot:first>
                                                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                    </template>
                                                                                </b-form-select>
                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </b-col>
                                                                    <b-col md="4">
                                                                        <validation-provider name="distrito" :rules="{ required: true }" v-slot="validationContext">
                                                                            <b-form-group label="Distrito:">
                                                                                <b-form-select :state="getValidationState(validationContext)" id="distrito" value-field=idDistrito text-field="nombre" :options="listaDistritos" v-model="datosCliente.idDistrito">
                                                                                    <template v-slot:first>
                                                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                    </template>
                                                                                </b-form-select>
                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </b-col> -->
                                                            <b-col md="4">
                                                                <validation-provider name="departamento" rules="required" v-slot="{errors}">
                                                                    <b-form-group label="Departamento:" class="mb-2">
                                                                        <v-select :reduce="listaDepartamentos => listaDepartamentos.idDepartamento" label="nombre" @input="obtenerProvincia()" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosCliente.idDepartamento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosCliente.idDepartamento" :options="listaDepartamentos" @search:blur="blurDepartamento">
                                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                        </v-select>
                                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>

                                                            <b-col md="4">
                                                                <validation-provider name="provincia" rules="required" v-slot="{errors}">
                                                                    <b-form-group label="Provincia:" class="mb-2">
                                                                        <v-select :reduce="listaProvincias => listaProvincias.idProvincia" label="nombre" @input="obtenerDistrito()" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosCliente.idProvincia , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosCliente.idProvincia" :options="listaProvincias.filter(x => x.idDepartamento==datosCliente.idDepartamento)" @search:blur="blurProvincia">
                                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                        </v-select>
                                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>

                                                            <b-col md="4">
                                                                <validation-provider name="distrito" rules="required" v-slot="{errors}">
                                                                    <b-form-group label="Distrito:" class="mb-2">
                                                                        <v-select :reduce="listaDistritos => listaDistritos.idDistrito" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosCliente.idDistrito , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosCliente.idDistrito" :options="listaDistritos.filter(x => x.idProvincia==datosCliente.idProvincia)" @search:blur="blurDistrito">
                                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                        </v-select>
                                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="localidad" v-slot="validationContext">
                                                                    <b-form-group label="Localidad:" class="mb-2">
                                                                        <b-form-input autocomplete="off" :state="getValidationState(validationContext)" placeholder="Ingrese la localidad" v-model="datosCliente.localidad"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>

                                                            <b-col md="3">
                                                                <validation-provider name="código postal" v-slot="validationContext">
                                                                    <b-form-group label="Código Postal:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el código postal" v-model="datosCliente.codigoPostal"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="5">
                                                                <validation-provider name="path" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Path de login:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese path" v-model="datosCliente.path"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>

                                                            <b-col md="4">
                                                                <validation-provider name="contacto" v-slot="validationContext">
                                                                    <b-form-group label="Contacto:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el contacto" v-model="datosCliente.contacto"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="teléfono del contacto" :rules="{ numeric: true }" v-slot="validationContext">
                                                                    <b-form-group label="Teléfono del contacto:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono del contacto" v-model="datosCliente.telefonoContacto"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="correo del contacto" :rules="{ email: true }" v-slot="validationContext">
                                                                    <b-form-group label="Correo del contacto:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el correo del contacto" v-model="datosCliente.correoContacto"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>

                                                            <b-col md="4">
                                                                <validation-provider name="actividad económica" v-slot="validationContext">
                                                                    <b-form-group label="Actividad económica:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el actividad económica" v-model="datosCliente.actividadEconomica"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="teléfono" :rules="{ numeric: true }" v-slot="validationContext">
                                                                    <b-form-group label="Teléfono:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosCliente.telefono"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="correo" :rules="{ required: true, email: true }" v-slot="validationContext">
                                                                    <b-form-group label="Correo:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el correo" v-model="datosCliente.correo"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="web" v-slot="validationContext">
                                                                    <b-form-group label="Web:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la web" v-model="datosCliente.web"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="representante legal" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Representante legal:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el representante legal" v-model="datosCliente.representanteLegal"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="cargo" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Cargo:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el cargo" v-model="datosCliente.cargo"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <validation-provider name="dirección fiscal" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Dirección fiscal:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la dirección fiscal" v-model="datosCliente.direccionFiscal"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <validation-provider name="observaciones" v-slot="validationContext">
                                                                    <b-form-group label="Observaciones:" class="mb-2">
                                                                        <b-form-textarea :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese las observaciones" v-model="datosCliente.observaciones"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>

                                                            <b-col md="12 text-right my-2">
                                                                <b-button variant="success" type="submit">
                                                                    Actualizar información
                                                                </b-button>
                                                            </b-col>
                                                        </b-row>
                                                    </b-form>
                                                </validation-observer>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <!-- </CCardBody>
                                        </CCard> -->
                                </b-tab>
                                <b-tab>
                                    <template slot="title">
                                        <i class="fas fa-users fa-md"></i> Usuarios
                                    </template>
                                    <div class="mt-3">
                                        <b-row>

                                            <b-col md="12">
                                                <b-button size="sm" variant="dark" class="float-right" @click="modalRegistrarUsuario = true">
                                                    <i class="fas fa-plus fa-sm"></i><span> Agregar</span>
                                                </b-button>
                                            </b-col>
                                            <b-col md="12" class="mt-2">
                                                <b-table bordered hover show-empty mediun responsive outlined :items="listaUsuarios" :fields="cabeceraUsuarios" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template v-slot:cell(opciones)="param">

                                                        <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarUsuario(param)" variant="dark" class=" mr-1 mb-1">
                                                            <span class="btn-inner--icon">
                                                                <i class="fas fa-search my-0 mx-0"></i>
                                                            </span>
                                                        </b-button>
                                                        <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarUsuario(param)" variant="danger">
                                                            <span class="btn-inner--icon">
                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                            </span>
                                                        </b-button>
                                                    </template>
                                                    <template v-slot:cell(estado)="param">
                                                        <span v-if="param.item.estado==1">Inactivo</span>
                                                        <span v-if="param.item.estado==2">Activo</span>
                                                    </template>
                                                </b-table>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-tab>
                                <b-tab>
                                    <template slot="title">
                                        <i class="fas fa-cog fa-md"></i> Servicio/s
                                    </template>
                                    <div class="mt-3">
                                        <b-row>
                                            <!-- <b-col md="12">
                                                <b-button size="sm" variant="dark" class="float-right" @click="modalRegistrarServicio = true">
                                                    <i class="fas fa-plus fa-sm"></i><span> Agregar</span>
                                                </b-button>
                                            </b-col> -->
                                            <b-col md="12" class="mt-2">
                                                <b-table bordered hover show-empty mediun responsive outlined :items="listaServicios" :fields="camposServicios" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template v-slot:cell(opciones)="param">
                                                        <b-button v-if="param.item.isActivo==1" @click="cambiarIsActivo(2, param.item.idDetalleVenta)" v-c-tooltip.hover.click="'Modificar'" size="sm" variant="success">
                                                            <span class="btn-inner--icon">
                                                                <i class="fas fa-check my-0 mx-0"></i>
                                                            </span>
                                                        </b-button>
                                                        <b-button v-else-if="param.item.isActivo==2" @click="cambiarIsActivo(1, param.item.idDetalleVenta)" size="sm" v-c-tooltip.hover.click="'Modificar'" variant="danger">
                                                            <span class="btn-inner--icon">
                                                                <i class="fas fa-times  my-0 mx-0"></i>
                                                            </span>
                                                        </b-button>
                                                    </template>
                                                    <!-- 
                                                    <template v-slot:cell(isActivo)="param">
                                                        <b-icon variant="success" v-if="param.item.isActivo==2" icon="check-circle-fill"></b-icon>
                                                        <b-icon variant="danger" v-else icon="x-circle-fill"></b-icon>
                                                    </template> -->
                                                    <template #cell(fechaFinContrato)="param">
                                                        <span class="mr-2">{{param.item.fechaFinContrato}}</span>
                                                        <span class="h5" v-if="validarFecha(param)">
                                                            <b-badge variant="danger">!</b-badge>
                                                        </span>
                                                    </template>
                                                </b-table>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-tab>
                                <b-tab>
                                    <template slot="title">
                                        <i class="fas fa-clock fa-md"></i> Historico Gestiones
                                    </template>
                                    <div class="mt-3">
                                        <b-row>
                                            <!-- <b-col md="12">
                                                <b-button @click="modalRegistrarHistorico = true" size="sm" variant="dark" class="float-right">
                                                    <i class="fas fa-plus fa-sm"></i><span> Agregar</span>
                                                </b-button>
                                            </b-col> -->
                                            <b-col md="12" class="mt-2">
                                                <b-table bordered hover show-empty mediun responsive outlined :items="listaHistoricos" :fields="camposHistoricos" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template v-slot:cell(opciones)="param">
                                                        <b-button v-if="param.item.activo==1" :to="{name: 'Gestión de oferta master', params:{id: param.item.idVenta,}}" v-c-tooltip.hover.click="'ver detalles'" size="sm" variant="dark" class=" mr-1 mb-1">
                                                            <span class="btn-inner--icon">
                                                                <i class="fas fa-search my-0 mx-0"></i>
                                                            </span>
                                                        </b-button>
                                                        <b-button v-if="param.item.activo==2" :to="{name: 'Gestión de venta master', params:{id: param.item.idVenta,}}" v-c-tooltip.hover.click="'ver detalles'" size="sm" variant="dark" class=" mr-1 mb-1">
                                                            <span class="btn-inner--icon">
                                                                <i class="fas fa-search my-0 mx-0"></i>
                                                            </span>
                                                        </b-button>
                                                        <!-- <b-button v-if="param" size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Anular'" variant="danger">
                                                            <span class="btn-inner--icon">
                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                            </span>
                                                        </b-button> -->
                                                    </template>
                                                    <template v-slot:cell(activo)="param">
                                                        <span v-if="param.item.activo==2">Venta</span>
                                                        <span v-if="param.item.activo==1">Oferta</span>
                                                    </template>
                                                    <template v-slot:cell(estado)="param">
                                                        <b-icon variant="success" v-if="param.item.estado==2" icon="check-circle-fill"></b-icon>
                                                        <b-icon variant="danger" v-else icon="x-circle-fill"></b-icon>
                                                    </template>
                                                </b-table>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-tab>
                                <b-tab>
                                    <template slot="title">
                                        <i class="fas fa-palette fa-md"></i> Personalización
                                    </template>
                                    <div class="mt-3">
                                        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(modificarTema)">
                                                <b-row>
                                                    <b-col md="12">
                                                        <b-row class="mb-4">
                                                            <b-col md="4" class="text-center ">
                                                                <CImg alt="logo" :style="style" :src="datosPersonalizacion.preview" class="img-fluid preview-theme" width="250" height="78"></CImg>
                                                            </b-col>

                                                            <b-col md="8">
                                                                <b-form-group label="Logo:" class="mb-2">
                                                                    <b-form-file class="mb-3" v-model="datosPersonalizacion.archivoMeta" :placeholder="datosCliente.nombreLogo!=null && datosCliente.nombreLogo!='null'  ? datosCliente.nombreLogo : 'Elija un archivo o arrástrelo aquí...'" accept=".png, .jpg, .jpge" browse-text="Subir" ref="input_logo" @change="handleFileUpload"></b-form-file>
                                                                    <!-- <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback> -->
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <validation-provider name="color primario" :rules="{ required: true }" v-slot="validationContext">
                                                            <b-form-group label="Color primario:" class="mb-2">
                                                                <b-form-input :value="datosPersonalizacion.primaryColor" @input="cambiarBackground($event)" type="color" :state="getValidationState(validationContext)"></b-form-input>
                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    </b-col>
                                                    <b-col md="6">
                                                        <validation-provider name="color secundario" :rules="{ required: true }" v-slot="validationContext">
                                                            <b-form-group label="Color secundario:" class="mb-2">
                                                                <b-form-input :value="datosPersonalizacion.secondaryColor" @input="cambiarColor($event)" type="color" :state="getValidationState(validationContext)"></b-form-input>
                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    </b-col>
                                                    <!-- <input type="color" v-model.lazy="datosPersonalizacion.secondaryColor"  v-on:input="cambiarColor($event)" /> -->
                                                    <b-col md="12 text-right my-2">
                                                        <b-button :disabled="disabled" :style="style" class="preview-theme" type="submit">
                                                            Actualizar
                                                        </b-button>
                                                    </b-col>
                                                </b-row>
                                            </b-form>
                                        </validation-observer>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
        </b-col>
    </b-row>

    <CModal :show.sync="modalRegistrarUsuario" :closeOnBackdrop="false" size="lg" color="dark">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Nuevo usuario</span></h6>

            <CButtonClose @click="modalRegistrarUsuario = false" class="text-white" />
        </template>
        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarUsuario)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="nombres" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombres:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuario()" :state="getValidationState(validationContext)" placeholder="Ingrese los nombres" v-model="datosUsuario.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="apellido paterno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido paterno:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuario()" :state="getValidationState(validationContext)" placeholder="Ingrese apellido paterno" v-model="datosUsuario.apellidoPaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="apellido materno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido materno:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuario()" :state="getValidationState(validationContext)" placeholder="Ingrese apellido materno" v-model="datosUsuario.apellidoMaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo de documento" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo de documento:" class="mb-2">
                                <v-select :reduce="comboTipoDocumentos => comboTipoDocumentos.idTipoDocumento" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosUsuario.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosUsuario.idTipoDocumento" :options="comboTipoDocumentos" @search:blur="blurTipoDocumento">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="documento de identidad" :rules="{required: true, numeric:true,min:8}" v-slot="validationContext">
                            <b-form-group label="Documento de identidad:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el documento de identidad" v-model="datosUsuario.numeroDocumento"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="teléfono" :rules="{numeric: true}" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosUsuario.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="correo" :rules="{required: true, email: true}" v-slot="validationContext">
                            <b-form-group label="Correo:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el correo" v-model="datosUsuario.email"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="usuario" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Usuario:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el usuario" v-model="datosUsuario.displayName"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <!-- <b-col md="6">
                        <validation-provider name="contraseña" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Contraseña:" class="mb-2">
                                <b-form-input type="password" autocomplete="on" :state="getValidationState(validationContext)" placeholder="Ingrese la contraseña" v-model="datosUsuario.contrasena"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col> -->
                    <!-- <b-col md="6">
                        <validation-provider name="perfil" rules="required" v-slot="{errors}">
                            <b-form-group label="Perfil:" class="mb-2">
                                <v-select :reduce="comboTipoPerfil => comboTipoPerfil.idTipoPerfil" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosUsuario.idTipoPerfil , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosUsuario.idTipoPerfil" :options="comboPerfiles" @search:blur="blurPerfil">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col> -->

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarUsuario = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarUsuario" :closeOnBackdrop="false" size="lg" color="dark">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar usuario</span></h6>
            <CButtonClose @click="modalActualizarUsuario = false" class="text-white" />
        </template>
        <validation-observer ref="observer4" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(modificarUsuario)">
                <b-row>
                    <b-col lg="6">
                        <validation-provider name="nombres" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombres:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese los nombres" v-model="datosActualizarUsuario.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="apellido paterno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido paterno:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese apellido paterno" v-model="datosActualizarUsuario.apellidoPaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="apellido materno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido materno:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese apellido materno" v-model="datosActualizarUsuario.apellidoMaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="tipo de documento" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo de documento:" class="mb-2">
                                <v-select :reduce="comboTipoDocumentos => comboTipoDocumentos.idTipoDocumento" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarUsuario.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarUsuario.idTipoDocumento" :options="comboTipoDocumentos" @search:blur="blurActualizarTipoDocumento">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="documento de identidad" :rules="{required: true, numeric:true, min:8}" v-slot="validationContext">
                            <b-form-group label="Documento de identidad:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el documento de identidad" v-model="datosActualizarUsuario.numeroDocumento"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="teléfono" :rules="{numeric: true}" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosActualizarUsuario.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                  
                    <b-col lg="6">
                        <validation-provider name="usuario" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Usuario:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el usuario" v-model="datosActualizarUsuario.displayName"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <!-- <b-col lg="6">
                        <validation-provider name="perfil" rules="required" v-slot="{errors}">
                            <b-form-group label="Perfil:" class="mb-2">
                                <v-select :reduce="comboPerfiles => comboPerfiles.idTipoPerfil" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarUsuario.idTipoPerfil , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarUsuario.idTipoPerfil" :options="comboPerfiles" @search:blur="blurActualizarPerfil">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col> -->

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarUsuario = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import UBIGEO from "@/helpers/Ubigeo";
import TipoDocumentos from "@/helpers/TipoDocumentos";
import firebase from 'firebase';
const dbClientes = firebase.firestore().collection('clientes');
const dbVentas = firebase.firestore().collection('ventas');
const dbServicios = firebase.firestore().collection('servicios');
const dbUsuario = firebase.firestore().collection('users');

export default {

    data() {
        return {
            datosCliente: {
                idCliente: "",
                numeroRuc: "",
                razonSocial: "",
                nombreComercial: "",
                idDepartamento: null,
                idProvincia: null,
                idDistrito: null,
                localidad: "",
                codigoPostal: "",
                direccionFiscal: "",
                contacto: "",
                telefonoContacto: "",
                correoContacto: "",
                actividadEconomica: "",
                telefono: "",
                correo: "",
                web: "",
                urlLogo: "",
                nombreLogo: '',
                representanteLegal: "",
                cargo: "",
                observaciones: "",
                path: "",
                estado: "",
            },

            datosSession: {
                idCliente: 0
            },

            datosHistoricos: {
                fecha: '',
                gestion: null,
                observaciones: ''
            },

            listaServicios: [],
            camposServicios: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "fechaInicioContrato",
                    label: "Fecha inicio contrato",
                    class: "text-center",
                },
                {
                    key: "fechaFinContrato",
                    label: "Fecha fin contrato",
                    class: "text-center",
                },
                {
                    key: "descripcion",
                    label: "Servicio",
                    class: "text-center",
                },
                {
                    key: "importeVenta",
                    label: "Precio",
                    class: "text-center",
                },
                /* {
                    key: "isActivo",
                    label: "Activo",
                    class: "text-center",
                }, */
                /* {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }, */
            ],
            cabeceraUsuarios: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "displayName",
                    label: "Usuario",
                    class: "text-center",
                },
                {
                    key: "estado",
                    label: "Estado",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],
            listaUsuarios: [],

            listaHistoricos: [],

            camposHistoricos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "fechaVenta",
                    label: "Fecha gestión",
                    class: "text-center",
                },
                {
                    key: "fechaInicioContrato",
                    label: "Fecha inicio",
                    class: "text-center",
                },
                {
                    key: "fechaFinContrato",
                    label: "Fecha fin",
                    class: "text-center",
                },
                {
                    key: "activo",
                    label: "Gestión",
                    class: "text-center",
                },

                {
                    key: "estado",
                    label: "Estado",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],

            datosUsuario: {
                nombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                idTipoDocumento: null,
                numeroDocumento: '',
                telefono: '',
                email: '',
                displayName: '',
                contrasena: '',
            },
            datosActualizarUsuario: {
                idUsuario: '',
                nombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                idTipoDocumento: null,
                numeroDocumento: '',
                telefono: '',
                email: '',
                displayName: '',
            },

            cargaApi: false,
            disabledBtnApi: false,

            modalRegistrarUsuario: false,
            modalActualizarUsuario: false,

            listaDistritos: [],
            listaProvincias: [],
            listaDepartamentos: [],

            disabled: false,
            datosPersonalizacion: {
                primaryColor: '',
                secondaryColor: '',
                accentColor: '',
                archivoMeta: null,
                preview: null
            },
            comboPerfiles: [],
            comboTipoDocumentos: [],
        }
    },
    methods: {
        cambiarBackground(e) {
            this.datosPersonalizacion.primaryColor = e;
        },
        cambiarColor(e) {
            this.datosPersonalizacion.secondaryColor = e;
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        blurPerfil() {
            this.computedFormUsuario.refs.perfil.validate();
        },
        blurActualizarPerfil() {
            this.computedActualizarFormUsuario.refs.perfil.validate();
        },
        blurActualizarTipoDocumento() {
            this.computedActualizarFormUsuario.refs['tipo de documento'].validate();
        },
        blurTipoDocumento() {
            this.computedFormUsuario.refs['tipo de documento'].validate();
        },
        validarFecha(param) {
            return moment(param.item.fechaFinContrato).isSameOrBefore(moment().format('YYYY-MM-DD'))
        },

        apiSunat() {
            let me = this;
            let mi_token = "76afdc4b32381ff72ff7a4d827f7856bb850e6228adc9e9f6f1c74e114510f22";
            me.cargaApi = true;
            axios
                .get("https://apiperu.dev/api/ruc/" + me.datosCliente.numeroRuc, {
                    headers: {
                        Authorization: `Bearer ${mi_token}`
                    },
                    responseType: "json",
                })
                .then((response) => {
                    if (response.data.data) {
                        me.datosCliente.razonSocial = response.data.data.nombre_o_razon_social;
                        me.datosCliente.direccionFiscal = response.data.data.direccion;
                        me.datosCliente.idDepartamento = response.data.data.ubigeo[0]
                        me.obtenerProvincia();
                        me.datosCliente.idProvincia = response.data.data.ubigeo[1]
                        me.obtenerDistrito();
                        me.datosCliente.idDistrito = response.data.data.ubigeo[2]
                    } else {
                        me.swat('error', 'No se encontró el número de ruc!')
                    }
                    me.cargaApi = false;
                })
                .catch((error) => {
                    me.cargaApi = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        generarNombreUsuario() {
            let me = this;
            let displayName = me.datosUsuario.nombre.substr(0, 1) + me.datosUsuario.apellidoPaterno + me.datosUsuario.apellidoMaterno.substr(0, 1);
            me.datosUsuario.displayName = displayName.toUpperCase();
        },
        generarNombreUsuarioActualizar() {
            let me = this;
            let displayName = me.datosActualizarUsuario.nombre.substr(0, 1) + me.datosActualizarUsuario.apellidoPaterno + me.datosActualizarUsuario.apellidoMaterno.substr(0, 1);
            me.datosActualizarUsuario.displayName = displayName.toUpperCase();
        },
        obtenerTipoDocumento() {
            let me = this;
            me.comboTipoDocumentos = TipoDocumentos;
        },
        /* obtenerTipoPerfiles() {
            let me = this;
            let idClienteEmpresa = this.$route.params.id;
            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/obtener-tipo-perfiles", {
                    params: {
                        idCliente: idClienteEmpresa
                    }
                })
                .then(function (response) {
                    me.comboPerfiles = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: "Clientes master"
                    })
                });
        }, */
        consultarCliente() {
            let idCliente = this.$route.params.id;
            let me = this;
            dbClientes.doc(idCliente)
                .onSnapshot((doc) => {
                    me.datosCliente.idCliente = doc.id;
                    me.datosCliente.numeroRuc = doc.data().numeroRuc;
                    me.datosCliente.razonSocial = doc.data().razonSocial;
                    me.datosCliente.nombreComercial = doc.data().nombreComercial;
                    me.obtenerDepartamento();
                    me.datosCliente.idDepartamento = doc.data().idDepartamento;
                    me.obtenerProvincia();
                    me.datosCliente.idProvincia = doc.data().idProvincia;
                    me.obtenerDistrito();
                    me.datosCliente.idDistrito = doc.data().idDistrito;
                    me.datosCliente.localidad = doc.data().localidad;
                    me.datosCliente.codigoPostal = doc.data().codigoPostal;
                    me.datosCliente.direccionFiscal = doc.data().direccionFiscal;
                    me.datosCliente.contacto = doc.data().contacto;
                    me.datosCliente.telefonoContacto = doc.data().telefonoContacto;
                    me.datosCliente.correoContacto = doc.data().correoContacto;
                    me.datosCliente.actividadEconomica = doc.data().actividadEconomica;
                    me.datosCliente.telefono = doc.data().telefono;
                    me.datosCliente.correo = doc.data().correo;
                    me.datosCliente.web = doc.data().web;
                    me.datosCliente.path = doc.data().path;
                    me.datosCliente.urlLogo = doc.data().urlLogo;
                    me.datosPersonalizacion.preview = doc.data().urlLogo;
                    me.datosCliente.nombreLogo = doc.data().nombreLogo;
                    me.datosCliente.representanteLegal = doc.data().representanteLegal;
                    me.datosCliente.cargo = doc.data().cargo;
                    me.datosCliente.observaciones = doc.data().observaciones;
                    me.datosCliente.estado = doc.data().estado;

                    me.datosPersonalizacion.primaryColor = doc.data().primaryColor;
                    me.datosPersonalizacion.secondaryColor = doc.data().secondaryColor;
                    me.datosPersonalizacion.accentColor = doc.data().accentColor;
                });
        },

        listarUsuarios() {
            let me = this;
            dbUsuario
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.$route.params.id))
                .onSnapshot((querySnapshot) => {
                    me.listaUsuarios = [];
                    querySnapshot.forEach((doc) => {
                        me.listaUsuarios.push({
                            idUsuario: doc.id,
                            email: doc.data().email,
                            nombre: doc.data().nombre,
                            apellidoPaterno: doc.data().apellidoPaterno,
                            apellidoMaterno: doc.data().apellidoMaterno,
                            telefono: doc.data().telefono,
                            idTipoDocumento: doc.data().idTipoDocumento,
                            estado: doc.data().estado,
                            displayName: doc.data().displayName,
                            numeroDocumento: doc.data().numeroDocumento,
                            idCliente: doc.data().idCliente,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.listaUsuarios.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                });
        },
        listarVentasOfertas() {
            let me = this;
            dbVentas.where("idCliente", "==", me.$route.params.id)
                .onSnapshot((querySnapshot) => {
                    me.listaVentas = [];
                    querySnapshot.forEach((doc) => {
                        me.listaHistoricos.push({
                            idVenta: doc.id,
                            fechaVenta: doc.data().fechaVenta,
                            fechaInicioContrato: doc.data().fechaInicioContrato,
                            fechaFinContrato: doc.data().fechaFinContrato,
                            activo: doc.data().activo,
                            estado: doc.data().estado,
                        })
                    });
                });
        },
        obtenerServiciosActivos() {
            let me = this;

            dbVentas.where("idCliente", "==", me.$route.params.id)
                .onSnapshot((querySnapshot) => {
                    me.listaVentas = [];
                    querySnapshot.forEach((doc) => {
                        for (const i in doc.data().listaServicios) {
                            dbServicios.doc(doc.data().listaServicios[i].idServicio)
                                .onSnapshot((docServ) => {
                                    me.listaServicios.push({
                                        idServicio: doc.data().listaServicios[i].idServicio,
                                        descripcion: docServ.data().descripcion,
                                        importeVenta: doc.data().listaServicios[i].importeVenta,
                                        coste: doc.data().listaServicios[i].coste,
                                        fechaInicioContrato: doc.data().fechaInicioContrato,
                                        fechaFinContrato: doc.data().fechaFinContrato,
                                    })
                                });
                        }
                    });
                });
        },
        obtenerDepartamento() {
            let me = this;
            me.listaDepartamentos = UBIGEO.Departamentos;
        },

        obtenerProvincia() {
            let me = this;
            (me.datosCliente.idProvincia = null),
            (me.datosCliente.idDistrito = null),
            (me.listaDistritos = []),

            me.listaProvincias = UBIGEO.Provincias;
        },

        obtenerDistrito() {
            let me = this;
            (me.datosCliente.idDistrito = null),
            me.listaDistritos = UBIGEO.Distritos;
        },
        modificarCliente() {
            let me = this;
            me.disabled = true;
            dbClientes.doc(me.datosCliente.idCliente).update({
                    numeroRuc: me.datosCliente.numeroRuc,
                    razonSocial: me.datosCliente.razonSocial,
                    nombreComercial: me.datosCliente.nombreComercial,
                    idDepartamento: me.datosCliente.idDepartamento,
                    idProvincia: me.datosCliente.idProvincia,
                    idDistrito: me.datosCliente.idDistrito,
                    localidad: me.datosCliente.localidad,
                    codigoPostal: me.datosCliente.codigoPostal,
                    direccionFiscal: me.datosCliente.direccionFiscal,
                    contacto: me.datosCliente.contacto,
                    telefonoContacto: me.datosCliente.telefonoContacto,
                    correoContacto: me.datosCliente.correoContacto,
                    actividadEconomica: me.datosCliente.actividadEconomica,
                    telefono: me.datosCliente.telefono,
                    correo: me.datosCliente.correo,
                    web: me.datosCliente.web,
                    path: me.datosCliente.path,
                    representanteLegal: me.datosCliente.representanteLegal,
                    cargo: me.datosCliente.cargo,
                    observaciones: me.datosCliente.observaciones,
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then(() => {
                    //console.log("Actualizado correctamente");
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.resetDatosCliente();
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },

        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.datosPersonalizacion.preview = null;
                    this.$refs["input_logo"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["input_logo"].reset();
                    return;
                }
                //this.swat('error', 'documento');
                const file = e.target.files[0];
                this.datosPersonalizacion.archivoMeta = this.$refs.input_logo.files[0];
                this.datosPersonalizacion.preview = URL.createObjectURL(file);
            }
        },

        async modificarTema() {
            let me = this;
            me.disabled = true;
            let miliseg = new Date().getTime();
            try {
                const file = me.datosPersonalizacion.archivoMeta;
                //console.log(file)
                if (file) {
                    const response = await firebase
                        .storage()
                        .ref(`admin/logos/${file.name}-`+miliseg)
                        .put(file);
                    const url = await response.ref.getDownloadURL();
                    me.datosCliente.nombreLogo = file.name;
                    me.datosCliente.urlLogo = url;
                }
            } catch (error) {
                me.file = null;
                me.datosCliente.nombreLogo = null;
                me.datosCliente.urlLogo = null;
                me.swat('warning', 'No se pudo subir el documento ')
            }
            dbClientes.doc(me.datosCliente.idCliente).update({
                urlLogo: me.datosCliente.urlLogo,
                nombreLogo: me.datosCliente.nombreLogo,
                primaryColor: me.datosPersonalizacion.primaryColor,
                secondaryColor: me.datosPersonalizacion.secondaryColor,
            })
                .then((docRef) => {
                    me.swat('success', 'Modificado satisfactoriamente')
                })
                .catch((error) => {
                    me.swat('error', 'Algo salió mal!')
                });
            me.disabled = false;

            /* const formData = new FormData();
            formData.append("file", me.datosPersonalizacion.archivoMeta);
            formData.append("folder", 'logo');
            formData.append("idClienteSession", "0");
            formData.append("idCliente", me.datosCliente.idCliente);
            formData.append("primaryColor", me.datosPersonalizacion.primaryColor);
            formData.append("secondaryColor", me.datosPersonalizacion.secondaryColor);
            //formData.append("accentColor", me.datosPersonalizacion.accentColor);
            formData.append("urlLogo", me.datosCliente.urlLogo);
            formData.append("nombreLogo", me.datosCliente.nombreLogo);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/personalizar-cliente",
                    formData
                )
                .then((response) => {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.consultarCliente();
                    me.resetDatosPersonalizacion();
                    me.disabled = false;
                })
                .catch((err) => {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                }); */
        },
        registrarUsuario() {
            let me = this;
            me.disabled = true;
            firebase
                .auth()
                .createUserWithEmailAndPassword(me.datosUsuario.email, me.datosUsuario.numeroDocumento)
                .then((res) => {
                    //actualizar el usuario
                    if (res) {
                        dbUsuario.add({
                                displayName: me.datosUsuario.usuario,
                                email: me.datosUsuario.email,
                                nombre: me.datosUsuario.nombre,
                                apellidoPaterno: me.datosUsuario.apellidoPaterno,
                                apellidoMaterno: me.datosUsuario.apellidoMaterno,
                                telefono: me.datosUsuario.telefono,
                                rol: 'guia',
                                estado: 2,
                                idTipoDocumento: me.datosUsuario.idTipoDocumento,
                                numeroDocumento: me.datosUsuario.numeroDocumento,
                                idCliente: me.$route.params.id,
                                uidAuth: res.user.uid,
                                displayName: me.datosUsuario.displayName,
                                fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                                fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                            })
                            .then((docRef) => {
                                me.swat('success', 'Registrado satisfactoriamente')
                                me.modalRegistrarUsuario = false
                                me.disabled = false;
                            })
                            .catch((error) => {
                                me.disabled = false;
                                me.swat('error', 'Algo salió mal!')
                            });
                    }
                })
                .catch((err) => {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
            // let me = this;
            // me.disabled = true;
            // axios
            //     .post(
            //         CONSTANTES.URL_RUTA_SERVICIOS + "master/registrar-usuario", {
            //             datosUsuario: me.datosUsuario,
            //             idCliente: me.datosCliente.idCliente
            //         }, {
            //             headers: {
            //                 Authorization: `Bearer ${localStorage.token}`
            //             },
            //         }
            //     )
            //     .then(function (response) {
            //         let color = response.data.resultado == 1 ? "success" : "error";
            //         me.disabled = false;
            //         if (response.data.alert) {
            //             return me.swat(color, response.data.mensaje);
            //         }
            //         me.swat(color, response.data.mensaje);
            //         me.listarUsuarios();
            //         me.modalRegistrarUsuario = false;
            //     })
            //     .catch(function (error) {
            //         me.swat('error', 'Algo salió mal!')
            //         me.disabled = false;
            //     });
        },
        modificarUsuario() {
            let me = this;
            me.disabled = true;
            dbUsuario.doc(me.datosActualizarUsuario.idUsuario).update({
                    displayName: me.datosActualizarUsuario.usuario,
                    nombre: me.datosActualizarUsuario.nombre,
                    apellidoPaterno: me.datosActualizarUsuario.apellidoPaterno,
                    apellidoMaterno: me.datosActualizarUsuario.apellidoMaterno,
                    telefono: me.datosActualizarUsuario.telefono,
                    idTipoDocumento: me.datosActualizarUsuario.idTipoDocumento,
                    numeroDocumento: me.datosActualizarUsuario.numeroDocumento,
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then(() => {
                    //console.log("Actualizado correctamente");
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.modalActualizarUsuario = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        cambiarIsActivo(isActivo, idDetalleVenta) {
            let me = this
            let texto = isActivo == 1 ? 'desactivar' : 'activar';

            // me.$swal.fire({
            //     title: '¿Estas seguro de ' + texto + ' el servicio?',
            //     text: "¡No podrás revertir esto!",
            //     icon: isActivo == 1 ? 'warning' : 'success',
            //     showCancelButton: false,
            //     confirmButtonColor: isActivo == 1 ? '#d33' : '#2EB85C',
            //     confirmButtonText: isActivo == 1 ? 'Desactivar' : 'Activar',
            // }).then((result) => {
            //     if (result.isConfirmed) {
            //         axios
            //             .post(CONSTANTES.URL_RUTA_SERVICIOS + "master/cambiar-is-activo-servicio", {
            //                 isActivo: isActivo,
            //                 idDetalleVenta: idDetalleVenta,
            //             }, {
            //                 headers: {
            //                     Authorization: `Bearer ${localStorage.token}`,
            //                 }
            //             })
            //             .then(function (response) {
            //                 let color = response.data.resultado == 1 ? "success" : "error";
            //                 me.swat(color, response.data.mensaje)
            //                 me.obtenerServiciosActivos();
            //             })
            //             .catch(function (error) {
            //                 me.swat('error', 'Algo salió mal!')
            //             });
            //     }
            // })
        },
        abrirModalActualizarUsuario(param) {

            this.datosActualizarUsuario.nombre = param.item.nombre;
            this.datosActualizarUsuario.apellidoPaterno = param.item.apellidoPaterno;
            this.datosActualizarUsuario.apellidoMaterno = param.item.apellidoMaterno;
            this.datosActualizarUsuario.idTipoDocumento = param.item.idTipoDocumento;
            this.datosActualizarUsuario.numeroDocumento = param.item.numeroDocumento;
            this.datosActualizarUsuario.telefono = param.item.telefono;
            this.datosActualizarUsuario.email = param.item.email;
            this.datosActualizarUsuario.displayName = param.item.displayName;
            this.datosActualizarUsuario.idUsuario = param.item.idUsuario;
            this.modalActualizarUsuario = true;
        },
        resetModalRegistrarUsuario() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });
            this.datosUsuario.nombre = '';
            this.datosUsuario.apellidoPaterno = '';
            this.datosUsuario.apellidoMaterno = '';
            this.datosUsuario.numeroDocumento = '';
            this.datosUsuario.email = '';
            this.datosUsuario.telefono = '';
            this.datosUsuario.displayName = '';
            this.datosUsuario.idTipoDocumento = '';

        },
        eliminarUsuario(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el usuario?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                        dbUsuario.doc(param.item.idUsuario).update({
                            estado: 1,
                        })
                        .then(() => {
                            me.swat('success', 'Eliminado satisfactoriamente')
                        })
                        .catch((error) => {
                            me.swat('error', 'Algo salió mal!')
                        });
                    // axios
                    //     .post(CONSTANTES.URL_RUTA_SERVICIOS + "master/eliminar-usuario", {
                    //         idUsuario: param.item.idUsuario,
                    //     }, {
                    //         headers: {
                    //             Authorization: `Bearer ${localStorage.token}`,
                    //         }
                    //     })
                    //     .then(function (response) {
                    //         let color = response.data.resultado == 1 ? "success" : "error";
                    //         me.swat(color, response.data.mensaje)
                    //         me.listarUsuarios();
                    //     })
                    //     .catch(function (error) {
                    //         me.swat('error', 'Algo salió mal!')
                    //     });
                }
            })
        },
        resetModalActualizarUsuario() {
            this.$nextTick(() => {
                this.$refs.observer4.reset();
            });
        },

        resetDatosCliente() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
        },
        resetDatosPersonalizacion() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosPersonalizacion.preview = null;
            this.datosPersonalizacion.archivoMeta = null;
        },
        blurDepartamento() {
            this.computedForm.refs.departamento.validate();
        },
        blurProvincia() {
            this.computedForm.refs.provincia.validate();
        },
        blurDistrito() {
            this.computedForm.refs.distrito.validate();
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        style() {
            return {
                '--primary-color': this.datosPersonalizacion.primaryColor,
                '--secondary-color': this.datosPersonalizacion.secondaryColor,
                '--accent-color': this.datosPersonalizacion.accentColor,
            }
        },
        computedFormUsuario() {
            return this.$refs.observer3;
        },
        computedActualizarFormUsuario() {
            return this.$refs.observer4;
        },

    },
    watch: {
        modalRegistrarUsuario: function (val) {
            if (val == false) {
                this.resetModalRegistrarUsuario();
            }
        },
        modalActualizarUsuario: function (val) {
            if (val == false) {
                this.resetModalActualizarUsuario();
            }
        },
        'datosCliente.numeroRuc': function (val) {
            if (val.length == 11) {
                this.disabledBtnApi = false
            } else {
                this.disabledBtnApi = true
            }
        },
    },
    mounted() {
        this.consultarCliente();
        this.obtenerTipoDocumento();
        // this.obtenerTipoPerfiles();
        this.listarUsuarios();
        this.listarVentasOfertas();
        this.obtenerServiciosActivos();

    }

}
</script>

<style lang="scss">
.preview-theme {
    background-color: var(--primary-color);
    color: var(--secondary-color);
}

.preview-theme:hover {
    background-color: var(--primary-color);
    color: var(--accent-color);
}
</style>
